#header{
    height: 118px;
    border-bottom: 1px solid #151934;
    .inner-container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: inherit;
        .logo{
            .imgLogo{
                max-width: 125px;
            }
        }
        .menu{
            a {
                //styleName: Subtitle/RG;
                font-family: Inter;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0em;
                color: #ffffff;
                text-decoration: none;
                margin: 0px 10px;
            }
        }
        .contactus{

        }
        .mobile{
            display: none;
            height: inherit;
            justify-content: center;
            border-left-color: #151934;
            border-left-style: solid;
            border-left-width: 1px;
            button{
                color: #ffffff;
            }
        }
       
    }

    @media only screen and (max-width: 767px) {
        height: 57px;
        .inner-container{
            padding-right: 0px;
            .logo{
                .imgLogo{
                    max-width: 64px;
                }
            }
            .menu, 
            .contactus{
                display: none;
            }
            .mobile{
                display: flex;
            }
        }
    }
}
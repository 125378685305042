.AppButton{
    .contactus,
    .pricing{
        font-family: Poppins;
        font-size: 16px;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 0em;
        text-transform: capitalize;
        color: #ffffff;
        border-radius: 36px;
        padding: 15px 32px;
        
    }
    .contactus{
        background-color: #5F14FF;
        &:hover{
            background-color: #5F14FF;
        }
    }
    .pricing{
        background: linear-gradient(261.47deg, rgba(21, 25, 52, 0.52) 14.6%, rgba(21, 25, 52, 0.08) 49.66%, rgba(21, 25, 52, 0.49) 87.77%),
        linear-gradient(0deg, #282D45, #282D45);
        &:hover{
            background: linear-gradient(261.47deg, rgba(21, 25, 52, 0.52) 14.6%, rgba(21, 25, 52, 0.08) 49.66%, rgba(21, 25, 52, 0.49) 87.77%),
        linear-gradient(0deg, #282D45, #282D45);
        }
        
    }
}
body{
    background-color: #00041A;
    padding: 0px;
    margin: 0px;
    height: 100vh;
}
#root {
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.inner-container{
    max-width: 1224px;
    margin: 0 auto;
    width: 100%;
}
@media only screen and (max-width: 1240px) {
    .inner-container{
        max-width: 980px;
    }
}

@media only screen and (max-width: 980px) {
    .inner-container{
        max-width: unset;
        width: unset;
        padding: 0px 20px;
    }
}
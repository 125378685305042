#footer{
    background-color: #060B27;
    padding-top: 100px;
    .inner-container{
        .getInTouch {
            min-height: 270px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0px 6%;
            position: relative;
            border-radius: 5px;
            overflow: hidden;
            &::before{
                content: "";
                position: absolute;
                top: -190px;
                left: -130px;
                width: 362px;
                height: 357px;
                background: linear-gradient(90.31deg, rgba(255, 255, 255, 0.07) 0.13%, rgba(255, 255, 255, 0) 73.58%);
                border-radius: 100%;
                transform: rotate(180deg);
                z-index: 1;
            }
            &::after{
                content: "";
                position: absolute;
                bottom: -190px;
                right: -130px;
                width: 362px;
                height: 357px;
                background: linear-gradient(90.31deg, rgba(255, 255, 255, 0.07) 0.13%, rgba(255, 255, 255, 0) 73.58%);
                border-radius: 100%;
                z-index: 1;
            }
            .overlay{
                position: absolute;
                top: 0;
                left: 0;
                right: 54px;
                bottom: 0;
                background-color: #00041A;
                z-index: 0;
            }
            .content{
                flex: 1;
                z-index: 1;
                max-width: 80%;
                .subText{
                    font-family: Inter;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 22px;
                    letter-spacing: 0.5px;
                    text-align: left;
                    color: #ffffff;
                    margin-bottom: 15px;
                }
                .mainText1{
                    margin-bottom: 10px;
                    span{
                        font-size: 16px;
                        font-weight: bold;
                    }
                }
                .mainText1, .mainText2{
                    font-family: Inter;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 24px;
                    letter-spacing: 0px;
                    text-align: left;
                    color: #ffffff;
                }
            }
            // .buttons {
            //     display: flex;
            //     justify-content: flex-end;
            //     align-items: center;
            //     flex: 1;
            //     z-index: 2;
            // }
        }
        .footerMenu {
            display: flex;
            justify-content: space-evenly;
            padding: 30px 0px;
            border-bottom: 1px solid #282D45;
            .menuHolder {
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                .menu-icon{
                    margin-right: 15px;
                }
                .menu-content{
                    display: flex;
                    justify-content: space-evenly;
                    align-items: center;
                    .menu-name{
                        font-family: Inter;
                        font-size: 18px;
                        font-weight: 600;
                        line-height: 24px;
                        letter-spacing: 0em;
                        color: #ffffff;
                        margin-right: 15px;
                    }
                    .menu-link{
                        font-family: Inter;
                        font-size: 15px;
                        font-weight: 400;
                        line-height: 24px;
                        letter-spacing: 0em;
                        color: #ffffff;
                        text-decoration: none;
                        margin-bottom: 2px;
                    }
                }
            }
        }
        .copy-right {
            min-height: 40px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 0px;
            .content{
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #8F9BB7;
                span{
                    color: #F6F6F7;
                }
            }
            .social-link {
                border: 1px solid #282D45;
                border-radius: 8px;
                padding: 5px;
                gap: 10px;
                width: 34px;
                height: 34px;
                min-width: unset;
                font-size: 16px;
                color: #ffffff;
                margin-left: 10px;
            }
        }
        @media only screen and (max-width: 980px) {
            .footerMenu {
                flex-wrap: wrap;
                justify-content: flex-start;
                .menuHolder {
                    margin-right: 10px;
                    margin-bottom: 10px;
                }
            }
        }

        @media only screen and (max-width: 767px) {
            .getInTouch {
                flex-direction: column;
                justify-content: center;
                padding: 20px;
                .overlay{
                    right: 0;
                }
                .content, .buttons{
                    flex: unset;
                }
                .content{
                    margin-bottom: 20px;
                    max-width: unset;
                    p{
                        text-align: center !important;
                    }
                    
                }
            }
            .copy-right {
                flex-direction: column;
                justify-content: center;
                .content{
                    margin-bottom: 10px;
                    text-align: center;
                }
            }
        }

        @media only screen and (max-width: 640px) {
            .getInTouch {
                .content{
                    .mainText{
                        font-size: 36px;
                        line-height: 43px;
                    }
                }
                .buttons{
                    flex-direction: column;
                    width: 100%;
                    .AppButton{
                        margin-right: 0px !important;
                        width: inherit;
                        button{
                            width: inherit;
                            margin-bottom: 10px;
                        }
                    }
                }
            }
            .footerMenu {
                flex-wrap: unset;
                flex-direction: column;
                align-items: flex-start;
                .menuHolder {
                    margin-right: 0px;
                    margin-bottom: 10px;
                    .menu-content{
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;
                        .menu-name{
                            margin-right: unset;
                        }
                        .menu-link{
                            word-break: break-all;
                        }
                    }
                }
            }
        }

    }
    @media only screen and (max-width: 440px) {
        background-color: unset;
        padding-top: 0px;
        .inner-container{
            background-color: #060B27;
            padding: 100px 20px 0px 20px;
            margin: 0px 10px;
            .getInTouch {
                padding: 10px;
            }
        }
    }

}
#whyChooseUs{
    display: flex;
    .inner-container{
        display: flex;
        flex-direction: column;
        padding: 15px 0px;
        .contentTitle{
            font-family: Inter;
            font-size: 32px;
            font-weight: 600;
            line-height: 38px;
            color: #ffffff;
            margin-bottom: 10px;
        }
        .content{
            font-family: Inter;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            color: #ffffff;
            margin-bottom: 10px;
            span{
                font-weight: 600;
            }
        }
        .contentSubTitle{
            font-family: Inter;
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            color: #ffffff;
            margin-bottom: 10px;
        }
    }

    @media only screen and (max-width: 980px) {
        .inner-container{
            padding: 15px;
            .contentTitle{
                font-size: 28px;
            }
        }
    }
}
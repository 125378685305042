.AppTestimonial{
    width: 320px;
    position: relative;
    &::after{
        content: "";
        height: 110px;
        border: 1px solid #282D45;
        border-radius: 10px;
        position: absolute;
        bottom: 0px;
        left: 10px;
        right: 10px;
        z-index: -1;
    }
    .content{
        border: 1px solid #282D45;
        border-radius: 10px;
        padding: 25px;
        gap: 25px;
        width: 80%;
        background-color: #0E1330;
        z-index: 1;
        .header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 26px;
            .author {
                display: flex;
                flex-direction: row;
                .avatar{
                    width: 40px;
                    height: 40px;
                    background: rgba(255, 255, 255, 0.2);
                    border-radius: 10px;
                    margin-right: 10px;
                }
                .profile{
                    .feature-name{
                        font-family: Inter;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 26px;
                        letter-spacing: 0em;
                        color: #F6F6F7;
                    }
                    .feature-jobtitle{
                        font-family: Inter;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 12px;
                        letter-spacing: 0em;
                        color: #DCDEE4;
                    }
                }
            }
        }
        .body{
            .feature-jobtitle{
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #F6F6F7;
            }
        }
    }
}
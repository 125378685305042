.AppFeature{
    max-width: 300px;
    padding: 30px 40px;
    gap: 30px;
    border-radius: 4px;
    border: 1px solid #282D45;
    background-color: #0E1330;
    min-height: 280px;
    .holder{
        margin-bottom: 12px;
        .feature-title{
            font-family: Poppins;
            font-size: 24px;
            font-weight: 700;
            line-height: 32px;
            letter-spacing: -0.03em;
            text-align: left;
            color: #ffffff;
        }
        .feature-description{
            font-family: Inter;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
            color: #8F9BB7;
        }
        .linkHolder{
            .viewBtn{
                text-decoration: none;
                font-family: Inter;
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
                color: #ffffff;
            }
        }
    }
}
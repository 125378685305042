#drawer{
    .sideContent{
        width: 350px;
        .side-header{
            display: flex;
            justify-content: flex-end;
            padding: 10px;
            a{
                color: #000000;
            }
        }
    }
    // @media only screen and (max-width: 440px) {
    //     .sideContent{

    //     }
    // }
}
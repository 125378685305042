#home{
    .title {
        font-family: Poppins;
        font-size: 56px;
        font-weight: 700;
        line-height: 72px;
        letter-spacing: 0em;
        text-align: center;
        color: #ffffff;
        margin-bottom: 30px;
    }
    .subTitle{
        font-family: Inter;
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: center;
        color: #ffffff;
        margin-bottom: 30px;
    }
    .gradient-text {
        text-align: center;
        font-size: 50px;
        font-weight: 700;
        font-family: Poppins;
        margin-bottom: 24px;
        background: linear-gradient(to right, #F4F4FF 0%, #7B8BFF 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        display: inline-flex;
    }
    .banner {
        .inner-container{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 80px 10px;
            max-width: 55%;
            margin: 0 auto;
            
            .buttons {
                display: flex;
                margin-bottom: 30px;
            }
            .image{
                img{
                    width: 100%;
                }
            }
        }
        
    }
    .costumer-centric-online{
        max-width: 1240px;
        margin: 0 auto;
        border-top: 1px solid #0E1330;
        border-bottom: 1px solid #0E1330;
        padding: 50px 20px;
        .content{
            max-width: 70%;
            margin: 0 auto;
            .inner-container{
                
            }
        }
        .features {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            gap: 15px;
            .featureHolder{
                max-width: 24%;
                .AppFeature{
                    // margin-bottom: 20px;
                    max-width: unset;
                }
            }
            
        }
        
    }
    .project-container {
        max-width: 900px;
        margin: 0 auto;
        border-top: 1px solid #0E1330;
        border-bottom: 1px solid #0E1330;
        padding: 50px 20px;
        .logo-container{
            width: 100%;
            display: flex;
            flex-flow: wrap;
            align-items: center;
            justify-content: center;
            gap: 60px;
            margin-top: 50px;
        }
    }
    .bim-container {
        max-width: 1240px;
        margin: 0 auto;
        padding: 50px 20px;
        display: flex;
        flex-direction: row;
        .left-container {
            width: 80%;
            margin-right: 12px;
        }
        .right-container {
            width: 100%;
        }
        .key-features-text {
            color: white;
            font-family: Inter;
            padding: 3px;
        }
        .key-features-desc {
            color: #DCDEE4;
            font-family: Inter;
            padding: 8px;
        }
        .key-features-content {
            display: flex;
            flex-direction: column;
        }
        .bim-image {
            height: 100%;
            width: 100%;
        }
        .item-container {
            display: contents;
        }
        
        @media only screen and (max-width: 810px) {
            display: flex;
            flex-direction: column-reverse;
            .left-container {
                width: 100%;
            }
            .right-container {
                margin-bottom: 20px;
            }
        }
    }
    .agri-container {
        max-width: 1240px;
        margin: 0 auto;
        padding: 50px 20px;
        display: flex;
        flex-direction: row;
        font-size: 16px;
        .left-container {
            width: 80%;
            margin-right: 12px;
        }
        .right-container {
            width: 100%;
        }
        .key-features-text {
            color: white;
            font-family: Inter;
            padding: 3px;
        }
        .key-features-desc {
            color: #DCDEE4;
            font-family: Inter;
            padding: 8px;
        }
        .key-features-content {
            display: flex;
            flex-direction: column;
        }
        .agri-image {
            height: 100%;
            width: 100%;
        }
        .item-container {
            display: contents;
        }

        @media only screen and (max-width: 810px) {
            display: flex;
            flex-direction: column-reverse;
            .left-container {
                width: 100%;
            }
            .right-container {
                margin-bottom: 20px;
            }
        }
    }
    .waka-container {
        max-width: 1240px;
        margin: 0 auto;
        padding: 50px 20px;
        .container {
            display: flex;
            flex-direction: row;
        }
        .left-container {
            width: 100%;
        }
        .right-container {
            width: 80%;
            margin-left: 12px;
        }
        .key-features-text {
            color: white;
            font-family: Inter;
            padding: 3px;
        }
        .key-features-desc {
            color: #DCDEE4;
            font-family: Inter;
            padding: 8px;
        }
        .key-features-content {
            display: flex;
            flex-direction: column;
        }
        .waka-logo {
            height: 80px;
            margin-bottom: 20px;
        }
        .waka-image {
            width: 100%;
        }
        .item-container {
            display: contents;
        }

        .pages_container {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 10px;
            width: 100%;
            height: 100%;
            div {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
            
                img {
                  max-width: 100%;
                  max-height: 100%;
                  object-fit: contain;
                }
            }
        }

        @media only screen and (max-width: 810px) {
            .container {
                display: contents;
            }
            .right-container {
                width: 100%;
            }
            .left-container {
                margin-bottom: 20px;
            }
        }
    }
    .ez-container {
        max-width: 1240px;
        margin: 0 auto;
        padding: 50px 20px;
        .container {
            display: flex;
            flex-direction: row;
        }
        .left-container {
            width: 100%;
        }
        .right-container {
            width: 80%;
            margin-left: 12px;
        }
        .key-features-text {
            color: white;
            font-family: Inter;
            padding: 3px;
        }
        .key-features-desc {
            color: #DCDEE4;
            font-family: Inter;
            padding: 8px;
        }
        .key-features-content {
            display: flex;
            flex-direction: column;
        }
        .ez-logo {
            height: 80px;
            margin-bottom: 20px;
        }
        .ez-image {
            width: 100%;
        }
        .item-container {
            display: contents;
        }

        .pages_container {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 10px;
            width: 100%;
            height: 100%;
            div {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
            
                img {
                  max-width: 100%;
                  max-height: 100%;
                  object-fit: contain;
                }
            }
        }

        @media only screen and (max-width: 810px) {
            .container {
                display: contents;
            }
            .right-container {
                width: 100%;
            }
            .left-container {
                margin-bottom: 20px;
            }
        }
    }
    .cyber-container {
        max-width: 1240px;
        margin: 0 auto;
        padding: 50px 20px;
        display: flex;
        flex-direction: row;
        .left-container {
            width: 80%;
            margin-right: 12px;
        }
        .right-container {
            width: 100%;
        }
        .key-features-text {
            color: white;
            font-family: Inter;
            padding: 3px;
        }
        .key-features-desc {
            color: #DCDEE4;
            font-family: Inter;
            padding: 8px;
        }
        .key-features-content {
            display: flex;
            flex-direction: column;
        }
        .cyber-image {
            height: 100%;
            width: 100%;
        }
        .item-container {
            display: contents;
        }

        @media only screen and (max-width: 810px) {
            display: flex;
            flex-direction: column-reverse;
            .left-container {
                width: 100%;
            }
            .right-container {
                margin-bottom: 20px;
            }
        }
    }
    .testimonials{
        padding: 50px 0px;
        .inner-container{
            .content{
                max-width: 70%;
                margin: 0 auto;
            }
        }
        .testimonial-holder{
            margin-bottom: 50px;
            .slick-slider{
                .author-holder{
                   display: flex !important; 
                   height: 300px;
                   .AppTestimonial{
                    width: unset;
                    flex: 1;
                   }
                }
                .slick-prev, .slick-next{
                    width: 37px;
                    height: 37px;
                    border: 1px solid #282D45;
                    border-radius: 5px;
                    background-color: #0E1330;
                }
                .slick-prev{
                    left: 46%;
                    bottom: -70px;
                    top: unset;
                    &::before{
                        content: '\f060';
                        font-family: FontAwesome;
                        color: #282D45;

                    }
                    &:hover{
                        &::before{
                            color: #8F9BB7;
                        }
                    }
                    
                }
                .slick-next{
                    right: 46%;
                    bottom: -70px;
                    top: unset;
                    &::before{
                        content: '\f061';
                        font-family: FontAwesome;
                        color: #282D45;
                    }
                    &:hover{
                        &::before{
                            color: #8F9BB7;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 1240px) {
        .costumer-centric-online{
            .features{
                .featureHolder{
                    max-width: 23.9%;
                }
            }
        }
        .testimonials{
            .testimonial-holder{
                .slick-slider{
                    .author-holder{
                        height: 330px;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 1140px) {
        .costumer-centric-online{
            .features{
                .featureHolder{
                    max-width: 23.8%;
                }
            }
        }
    }

    @media only screen and (max-width: 980px) {
        .title {
            font-size: 53px;
            line-height: 72px;
        }
        .subTitle{
            font-size: 18px;
            line-height: 26px;
        }
        .banner {
            .inner-container{
                max-width: 75%;
            }
        }
        .costumer-centric-online{
            .content{
                max-width: 90%;
            }
            .features{
                .featureHolder{
                    max-width: 49%;
                    .AppFeature{
                        max-width: unset;
                    }
                }
                
            }
        }
        .testimonials{
            .testimonial-holder{
                .slick-slider{
                    .author-holder{
                        height: 360px;
                    }
                    .slick-prev{
                        left: 44%;
                    }
                    .slick-next{
                        right: 44%;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 790px) {
        .costumer-centric-online{
            .features{
                .featureHolder{
                    max-width: 48.9%;
                }
            }
        }
    }

    @media only screen and (max-width: 767px) {
        .title {
            font-size: 46px;
            line-height: 62px;
        }
        .banner {
            .inner-container{
                max-width: 85%;
            }
        }
        .costumer-centric-online{
            .content{
                max-width: 100%;
            }
            .features{
                .featureHolder{
                    // max-width: 48.9%;
                }
            }
        }
        .testimonials{
            .testimonial-holder{
                .slick-slider{
                    .author-holder{
                        flex-direction: column;
                        height: 700px;
                        .AppTestimonial{
                            margin-bottom: 30px;
                            &::after{
                                bottom: -15px;
                                left: 50px;
                                right: 50px;
                            }
                            .content{
                                width: 100%;
                            }
                        }
                    }
                    .slick-prev{
                        left: 44%;
                    }
                    .slick-next{
                        right: 44%;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 720px) {
        .costumer-centric-online{
            .features{
                .featureHolder{
                    max-width: 48.8%;
                }
            }
        }
    }

    @media only screen and (max-width: 666px) {
        .costumer-centric-online{
            .features{
                .featureHolder{
                    max-width: 48.7%;
                }
            }
        }
    }

    @media only screen and (max-width: 640px) {
        .title {
            font-size: 42px;
            line-height: 56px;
            margin-bottom: 20px;
        }
        .banner {
            .inner-container{
                .buttons{
                    flex-direction: column;
                    width: 100%;
                    .AppButton{
                        margin-right: 0px !important;
                        margin-bottom: 10px;
                        width: 100%;
                        button{
                            width: 100%;
                        }
                    }
                }
            }
        }
        .costumer-centric-online{
            .features{
                .featureHolder{
                    max-width: 100%;
                }
            }
        }
        .testimonials{
            .testimonial-holder{
                .slick-slider{
                    .author-holder{
                        flex-direction: column;
                        height: 800px;
                        .AppTestimonial{
                            &::after{
                                left: 20px;
                                right: 20px;
                            }
                        }
                    }
                    .slick-prev{
                        left: 42%;
                    }
                    .slick-next{
                        right: 42%;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 440px) {
        .title {
            font-size: 36px;
            line-height: 52px;
            margin-bottom: 10px;
        }
        .banner {
            .inner-container{
                max-width: 90%;
            }
        }
        .testimonials{
            .testimonial-holder{
                .slick-slider{
                    .author-holder{
                        flex-direction: column;
                        height: 980px;
                        .AppTestimonial{
                            &::after{
                                left: 10px;
                                right: 10px;
                            }
                        }
                    }
                    .slick-prev{
                        left: 38%;
                    }
                    .slick-next{
                        right: 38%;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 330px) {
        .testimonials{
            .testimonial-holder{
                .slick-slider{
                    .author-holder{
                        flex-direction: column;
                        height: 1100px;
                    }
                    .slick-prev{
                        left: 35%;
                    }
                    .slick-next{
                        right: 35%;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 90%) { }
}